import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Config from '../../Config';
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

class Homepage extends Component {

 render() {

  let dims =  {w: 375, h: 812}; // iphone X
  dims.h = Math.min(812, Math.floor(window.innerHeight / 1.2));
  let ratio =  dims.h / 812;
  dims.w = Math.floor(dims.w * ratio);
  let vidPath = '/videos/gid_10/mobile/auto-jsl.mp4';

  return (

   <Grid container direction="row" justifyContent="center" alignItems="flex-start">
    <Grid container direction='column' alignItems='center' justifyContent='space-around' item xs={12} sm={7} spacing={8} >
     <img style={{
      width: '80%',
      maxWidth: 300,
      margin: '0 auto'
     }}
     src='https://api.trackauthoritymusic.com/sites/default/files/groups/covers/jetsetlove-icon4%201.png'
     alt="logo"/>
     <Grid item>
      <Typography variant='h1' style={{margin: 0}}>
       <Link to='/group/9' style={{textDecoration: 'none'}}>
        {Config.name}
       </Link>
      </Typography>
      <Typography variant='h3'>Explore musical journeys and destinations for your own adventures</Typography>
     </Grid>
     <Grid item style={{textAlign:'center', marginBottom:20}}>
      <Link to="/forms/group/10/playlists/start" style={{textDecoration: 'none'}}>
       <Button color='primary' variant='contained' style={{margin: 5}}>Start a Journey</Button>
      </Link>
      <Link to="/group/10/playlists" style={{textDecoration: 'none'}}>
       <Button color='primary' variant='contained' style={{margin: 5}}>Search Journeys</Button>
      </Link>
     </Grid>
    </Grid>
    <Grid item xs={12} sm={5} >
     <div className="mobile" style={{width:dims.w, height:dims.h, marginBottom:20}}>
      <div className="deviceMask" >
       <video
        style={{objectFit:'cover'}}
        width={'100%'} height={'100%'}
        autoPlay muted playsInline
        src={'https://trackauthoritymusic.com'+vidPath} type="video/mp4"
       />
      </div>
     </div>
    </Grid>
   </Grid>
  );
 }
}

export default Homepage;
